<template>
  <div class="success-wrapper">
    <div class="icon">
      <img src="@/assets/images/green-check.svg" alt="Success" />
    </div>
    <p class="thank-you">
      {{ $t('thank_you') }}
    </p>
    <p class="thank-you-text">
      {{ $t('thank_you_text') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'Success',
  props: {
    payload: {
      type: Object,
      required: false
    }
  }
}
</script>
<style scoped>
.success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you {
  box-sizing: border-box;
  background: #E8FFFA;
  border: 1px solid #BDE5DD;
  border-radius: 10px;
  margin: 25px auto;
  max-width: 300px;
  padding: 15px 25px;
}

.thank-you-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #649187;
  opacity: 0.7;
  max-width: 380px;
  margin: 0 auto 20px auto;
}
</style>
